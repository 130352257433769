export const Friendly = () => {
    return <div className="text friendly">
      <p>Hey there!</p>
  
      <p>Welcome to this little microsite of mine :) </p>
  
      <p>Whether you come from LinkedIn, Substack, or you just felt like typing “Noemi Gobel” into Google and seeing what pops up – I’m happy to see you.</p>
  
      <p>You know why?</p>
  
      <p className="highlight">I’m currently open to taking on new copywriting projects. </p>
  
      <p>Yes, that’s right!</p>
  
      <p>Because here’s the thing: I’ve worked with some really terrific B2B clients in the past 10 years. (You can see some of their names just below.)</p>
  
      <p>But I feel like I’m ready for something… different. </p>
  
      <p>Something more people-focused. </p>
  
      <p>Something where I can make a difference with my words.</p>
  
      <p>This could be a furniture company…</p>
  
      <p>Or a plant-based milk company…</p>
  
      <p>Or even a toy manufacturer! </p>
  
      <p>(I live in the Swedish countryside with three young kids and can’t eat dairy – hence the ideas.)</p>
  
      <p>Basically, I’d like to help YOUR brand’s messaging become even stronger.</p>
  
      <p className="highlight">How does that sound?</p>
  
      <p>If you think I’m the copywriter you need, just hit that GET IN TOUCH button at the bottom of this page and I’ll get back to you in a few hours. We’ll set up a free “chemistry call”: in other words, a no-strings-attached Google Meeting where we talk about your project and discover if I’m the right fit for your business.</p>
  
      <p>Can’t wait to hear from you!</p>
  
      <p>All the best in the meantime,<br/>
  Noemi Gobel</p>
  
    </div>
  };


  export const Blogger = () => {
    return <div className="text blogger">
      <p className="highlight title">From “Meh” to Marvellous: Season Your Brand With Some Strong Writing!</p>

<p>Firing up your phone nowadays can feel strangely similar to getting a fish pedicure.</p>

<p>All that content swirling around your screen, waiting for you to gently lower yourself in… And when you do, the army of articles, TikToks and WhatsApp messages gobbling up your attention is almost like swarms of Garra rufa fish nibbling at your toes. </p>

<p>Of course, fish pedicures have been banned from most Western societies by now. But the endless amount of content surrounding us? That’s here to stay. </p>

<p>The latest data shows us that, on a global level, people spend about six and a half hours online each day. And in that time slot, a large portion of our attention is divided between different types of content: ads, videos, social media updates, even whitepapers and business reports... Forbes reports that, by the end of 2024, the content marketing industry is supposed to reach a valuation of 600 billion dollars. </p>

<p>In other words: that endless amount of content I mentioned before is still growing. Every. Blessed. Day. With no end in sight.</p>

<p>So what about your own brand? Is there some tried-and-tested way to make sure your content draws customers in, like oh-so-many moths flocking to the proverbial flame?</p>

<p>Well, here’s the bad news: hundreds of experts, copywriters, content wizards and marketing moguls have tried cracking the code to this. And they’ve come up with some great ideas! If you have the time (and the budget), there are, literally, dozens of ways you could optimise your content at once – from striking graphics to a vertical video-based strategy with cliffhangers galore.</p>

<p>All of these ways originate from the same basic fact, however: you need to make your content memorable.</p>

<p>Or in other words: it’s time to quit playing safe.</p>

<p>Now, I know this is easier said than done. I’ve worked a lot with B2B companies over the past ten years, and let me tell you right now, most have iron-clad brand identities and Tone of Voice guidelines set in stone. Typically – but not always! – the older the brand, the less they’re willing to deviate from these guidelines, making it tricky to produce content that really grabs you by the collar. (I don’t mean viral content, mind you. Aiming for virality is a teenager’s MO. At least, that’s how I feel. But I digress.)</p>

<p>Sticking to your brand’s original ethos is important, of course. Content trends are fickle, and I’d be the last person to suggest you jump on the “demure and mindful” craze today instead of doing something that feels more authentic to your company’s mission.</p>

<p>But in a world where so many blog posts, social media updates and brand awareness campaigns just sink to the bottom of the Content Ocean surrounding us, with nary a trace (except for a handful of likes from other employees), the only way your precious content will get the attention it deserves is if your brand guidelines allow it to have some character. </p>

<p>And the easiest way to do this? Allow your content specialists, copywriters or general wordsmiths to write for the readers of today. Writing as you speak doesn’t mean you’re unintelligent – it just means you’ve understood the importance of accessibility. Using analogies doesn’t make you unprofessional, but it does foster comprehension. (Remember that fish pedicure example from above?) And adding a touch of humour or alliteration to your headlines may just mean the difference between someone clicking on your blog or scrolling past it.</p>

<p>No need to go crazy, of course. There’s an old kitchen saying: the better the meat, the less you need to add to it! Sometimes, top-grade salt and pepper is more than enough for the perfect Sunday roast. </p>

<p>But as someone who’s both written and consumed an indecent amount of content during the past decade, I beg of you: find someone to add that top-grade salt to your content dish. It will truly bring out all the hidden flavours of your brand – and convince your customers to come back for more.</p>

<p>No matter how many TikToks they’ve snacked on before.</p>

<p className="footer">---------------------------------------------------------------------------<br />
Noemi Gobel is a freelance copywriter and content specialist living in the Swedish countryside with her three children, hordes of books, and way too many spiders. She’s currently looking for some new projects where she can add some flavour to her clients’ brand voice – get in touch with her via the form below to set up a free, 30-minute “chemistry call” today!</p>
    </div>
};

export const Fairytale = () => {
  return <div className="text fairytale">
  <p>Once upon a time, far up in the north of the world, there lived a wordsmith.</p>

  <p>No one knew how she had gotten there: with her dark, curly hair and thick-rimmed glasses, she seemed very different to the fair, tall people who lived in her village. Her husband was more like them, with long hair and a tall, thin frame. They lived in a small red house just outside the village with their three children, and laughed and shouted and lived together quite harmoniously.</p>

  <p>But the fair villagers didn’t understand what the wordsmith actually did all day. They would walk past her home several times during the week, when all the village children were at school, and the men were at work in the fields – and each time, they’d see the wordsmith through a window, writing at her desk, sometimes staring into the distance, but always occupied with her own thoughts.  </p>

  <p>What was she doing? Was she a witch? What is a wordsmith, anyway?  – they wondered among themselves.</p>

  <p>One foggy afternoon, some of the villagers were walking past the little red house when suddenly, they heard a loud bang from inside the walls. They stopped and stared until, all at once, the wordsmith came running out into the garden, running her hands through her hair and muttering angrily. </p>

  <p>“Stupid, useless, good-for-nothing…” – was all they could make out, but it was enough to set them running. All except for the oldest villager: his cane had fallen to the ground and he was having a hard time picking it up. The wordsmith noticed this, and sighed loudly. Then she went over to the old villager, who shrunk back from her, timidly. </p>

  <p>“Here you go,” said the wordsmith, and handed the cane over to the old villager. He blinked, expecting her to do something strange again – then plucked up his courage and asked: “Begging your pardon, Miss, but what was that noise just before?” And he gestured towards the house.</p>

  <p>The wordsmith looked at him blankly, then it dawned on her. “The bang? Oh, that was just me.”</p>

  <p>“You?”</p>

  <p>The wordsmith sighed. “I write spells for a living, you see, and I was just finishing one up for a friend in the next village when a spider decided to dangle itself before my nose!” She shuddered. “I DETEST spiders. Especially when they surprise me like that.”</p>

  <p>The old villager’s curiosity got the better of him. He gripped his cane tighter and asked, “So did you… magic it away, eh?”</p>

  <p>To his surprise, the wordsmith burst out laughing. </p>

  <p>“Oh no! My spells don’t work like that, they can only draw things in, not send them away…” She shrugged, still chuckling. “I just ran out here and left it on my desk, hoping it would go away on its own. My chair must have toppled over, maybe that’s what you heard?”</p>

  <p>The old villager’s courage had returned, and he stood up a bit straighter.</p>

  <p>“You know what, Miss? I could take care of that spider for you, if you’d like.”</p>

  <p>“Would you really? That would be wonderful!” – said the wordsmith gratefully, and guided the old villager in.</p>

  <p>A few hours later, her husband arrived with the three children, each of them tired after a long day. The children ran into the house and yelled with joy when they found a small, quiet Jack Russel terrier lying next to the wordsmith’s desk. </p>

  <p>“Careful! He’s not as young as he once was,” – said the wordsmith after hugging them, then turned to her husband, who was holding a cane in his hands, surprised. </p>

  <p>“Could you put that away, dear? I don’t want the children to see it.”</p>
  </div>
};

export const AI = () => {
  return <div className="text ai">
  <p className="highlight">I gave the following prompt to ChatGPT on October 24, 2024: “Based on what you can find about "Noemi Gobel" online, could you write a short paragraph about her professional strengths and achievements?” This is what ChatGPT came up with:</p>

  <p>“Noemi Gobel is a seasoned copywriter, content strategist, and communications manager based in Lund, Sweden. </p>

  <p>With a focus on crafting compelling narratives, she helps brands establish strong connections with their audiences through clear and impactful messaging. </p>

  <p>Noemi's work spans diverse sectors, including digital content and strategic communication, where she excels in developing tone of voice and managing complex communication projects. </p>

  <p>Her ability to blend creativity with strategy makes her a valuable asset in any content-driven endeavour.”</p>

  <p className="highlight">…Find out for yourself if ChatGPT got it right – get in touch with me through the form below to set up a free, no-strings-attached “chemistry call” through Google Meet!</p>
  </div>
};