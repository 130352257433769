const TONE = {
  FRIENDLY: 1,
  BLOGGER: 2,
  FAIRYTALE: 3,
  WHAT_AI_THINKS: 4
};

export const TONE_NAME = {
  1: "Friendly",
  2: "Blogger",
  3: "Fairytale",
  4: "What AI Thinks"
}

export default TONE;