import React, { useState } from 'react';
import Range from './Range';
import Text from './Text';
import TONE, {TONE_NAME} from './tone';
import Form from './Form';
import './App.css';

function App() {
  const [tone, setTone] = useState(TONE.FRIENDLY);
  return (
    <div className="app">
      <Range value={tone} setValue={setTone} />
      <h2>{TONE_NAME[tone]}</h2>
      <Text tone={tone} />
      <Form />
    </div>
  );
}

export default App;
