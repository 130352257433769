import './Range.css';
import TONE from './tone';

function Range({ value, setValue }) {
  return (
    <div className="range-wrapper">
      <input
        className="range"
        type="range"
        value={value}
        min="1"
        max="4"
        step="1"
        onInput={({target}) => setValue(target.value)}
      />
      <span className={`fake-circle fake-circle-1 ${value}`} onClick={() => setValue(TONE.FRIENDLY)} />
      <span className={`fake-circle fake-circle-2 ${value}`} onClick={() => setValue(TONE.BLOGGER)} />
      <span className={`fake-circle fake-circle-3 ${value}`} onClick={() => setValue(TONE.FAIRYTALE)} />
      <span className={`fake-circle fake-circle-4 ${value}`} onClick={() => setValue(TONE.WHAT_AI_THINKS)} />
    </div>
  );
}

export default Range;
