import TONE from './tone';
import './Text.css';
import {Friendly, Blogger, Fairytale, AI} from './TextSource';

const texts = {
  [TONE.FRIENDLY]: <Friendly />,
  [TONE.BLOGGER]: <Blogger />,
  [TONE.FAIRYTALE]: <Fairytale />,
  [TONE.WHAT_AI_THINKS]: <AI />
};

const Text = ({ tone }) => texts[tone];

export default Text;